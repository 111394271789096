import React from 'react'
import Lightbox from 'react-image-lightbox';

/*

rear-1.jpg:                1800x1200, components 3
patio-table-1.jpg:         1920x1280, components 3


front-2.jpg:               793x593, components 3
garden-1.jpg:              790x594, components 3
garden-2.jpg:              792x596, components 3

patio-table-2.jpg:         793x591, components 3
pond-1.jpg:                786x596, components 3
pond-2.jpg:                791x595, components 3

pond-3.jpg:                791x593, components 3
pool-1.jpg:                792x592, components 3
rear-3.jpg:                792x595, components 3


parking-lot-tables-1.jpg:  1632x1224, components 3
patio-chairs-1.jpg:        1632x1224, components 3

stairs-altar-1.jpg:        960x1280, components 3
front-1.jpg:               1000x622, components 3

stairs-2.jpg:              1224x1632, components 3
pool-2.jpg:                1291x968, components 3

rear-4.jpg:                547x702, components 3
rear-2.jpg:                1200x793, components 3






*/

import Img00 from "../images/grounds/rear-1.jpg"
import Img01 from "../images/grounds/patio-table-1.jpg"
import Img02 from "../images/grounds/front-2.jpg"
import Img03 from "../images/grounds/garden-1.jpg"
import Img04 from "../images/grounds/garden-2.jpg"
import Img05 from "../images/grounds/patio-table-2.jpg"
import Img06 from "../images/grounds/pond-1.jpg"
import Img07 from "../images/grounds/pond-2.jpg"
import Img08 from "../images/grounds/pond-3.jpg"
import Img09 from "../images/grounds/pool-1.jpg"
import Img10 from "../images/grounds/rear-3.jpg"
import Img11 from "../images/grounds/parking-lot-tables-1.jpg"
import Img12 from "../images/grounds/patio-chairs-1.jpg"
import Img13 from "../images/grounds/pool-2.jpg"
import Img14 from "../images/grounds/front-3.jpg"
import Img15 from "../images/grounds/rear-2.jpg"
import Img16 from "../images/grounds/rear-4.jpg"
import Img17 from "../images/grounds/stairs-2.jpg"
import Img18 from "../images/grounds/stairs-altar-1.jpg"

const images = [
    Img00,
    Img01,
    Img02,
    Img03,
    Img04,
    Img05,
    Img06,
    Img07,
    Img08,
    Img09,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18
 ];

export default class PhotoLightboxGrounds extends React.Component {

    
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
        <div>
            <div class="row">
                <div class="col-sm-6">
                    <img src={Img00} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:0 })} />
                </div>
                <div class="col-sm-6">
                    <img src={Img01} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:1 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8">
                    <img src={Img15} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:15 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img16} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:16 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img17} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:17 })} />
                </div>
                <div class="col-sm-8">
                    <img src={Img13} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:13 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img02} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:2 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img03} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:3 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img04} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:4 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img05} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:5 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img06} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:6 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img07} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:7 })} />
                </div>
            </div>            

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img08} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:8 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img09} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:9 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img10} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:10 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <img src={Img11} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:11 })} />
                </div>
                <div class="col-sm-6">
                    <img src={Img12} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:12 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img18} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:18 })} />
                </div>
                <div class="col-sm-8">
                    <img src={Img14} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:14 })} />
                </div>
            </div>
                        
           {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
    )
  }
}