import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import 'react-image-lightbox/style.css';

import Header from "../components/header";
import FixedFooter from "../components/footer"
import PhotoLightboxGrounds from "../components/photoLightboxGrounds";

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

const GroundsPage = () => {
  return (
    <main>
      <title>The Grounds at Fieldstone Farm</title>
      <Header></Header>      
      <Container style={headingStyles}>
        <PhotoLightboxGrounds/>
      </Container>
      <FixedFooter/>
    </main>
  )
}

export default GroundsPage